<template>
  <MainLayout
    v-if="issue"
    :back-to="backToManage ? '/manage/support/queue/unresolved' : '/support'"
    back-to-label="Suporte"
    :title="issue.subject"
    :short-title="issue.key"
  >
    <template v-slot:content>
      <div class="has-margin-top has-margin-bottom">
        <span class="is-size-2 has-margin-right">#{{ issue.key }}</span>
        <span>{{ issue.created_date | formatDateTime }}</span>
      </div>
      <div v-if="issue" class="panel">
        <div class="panel-header is-flex is-flex-align-center is-flex-space-between">
          <div>
            <b-tag v-if="issue.status == 'new'" size="is-medium" type="is-warning">{{
              $t('support.status.new')
            }}</b-tag>
            <b-tag v-else-if="issue.status == 'open'" size="is-medium" type="is-primary">{{
              $t('support.status.open')
            }}</b-tag>
            <b-tag v-else size="is-medium" type="is-black">{{ $t('support.status.closed') }}</b-tag>
          </div>
          <div v-if="$store.getters.userPermissions.isProcedureManager">
            <button class="button is-primary is-outlined" @click="openNewReply">{{ $t('actions.reply') }}</button>
          </div>
        </div>
        <div class="panel-body box">
          <div class="content" v-html="issue.description"></div>
          <div v-if="issue.files.length" class="panel-body">
            <div class="label has-margin-bottom">{{ $t('support.attachments') }}</div>
            <RecordFile v-for="file in issue.files" :key="file.id" :file="file" @download="downloadIssueFile" />
          </div>
        </div>
      </div>
      <div v-if="issue" class="panel">
        <div class="panel-header is-flex is-flex-align-center is-flex-space-between">
          <div>
            <h2>{{ $tc('support.messages.title', 2) }}</h2>
          </div>
          <div>
            <button class="button is-text" @click="openNewReply">
              {{ $t('support.messages.new') }}
            </button>
          </div>
        </div>
        <div class="panel-body">
          <div v-if="issue.messages.length">
            <div v-for="message in issue.messages" :key="message.key" class="record">
              <div class="record-content">
                <div class="record-header has-margin-bottom">
                  {{ $t('support.messages.replyFrom') }} <strong>{{ message.user.name }}</strong> {{ $t('in') }}
                  {{ message.created_date | formatDateTime }}
                </div>
                <div class="indent">
                  <div class="content" v-html="message.description"></div>
                  <div v-if="message.files.length">
                    <div class="label has-margin-bottom">{{ $t('support.attachments') }}</div>
                    <RecordFile
                      v-for="file in message.files"
                      :key="file.id"
                      :file="file"
                      @download="downloadMessageFile"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="has-margin-top-medium has-margin-bottom">
              <button class="button is-outlined is-primary" @click="openNewReply">
                {{ $t('support.messages.new') }}
              </button>
            </div>
          </div>
          <div v-else class="box has-text-centered is-meta">{{ $t('support.messages.noReply') }}</div>
        </div>
      </div>
    </template>
    <template v-slot:modals>
      <ModalAddEditIssueMessage :issue="issue" :show.sync="isNewReplyModalActive" @close="closeNewReplyModal" />
    </template>
  </MainLayout>
</template>

<script>
import SupportService from '@/services/SupportService'
// import AuthService from '@/services/AuthService'

import MainLayout from '@/components/layouts/MainLayout'
import RecordFile from '@/components/records/RecordFile'
import ModalAddEditIssueMessage from '@/components/modals/ModalAddEditIssueMessage'
// import UserPhoto from '@/components/user/UserPhoto'

export default {
  components: {
    MainLayout,
    RecordFile,
    ModalAddEditIssueMessage,
    // UserPhoto,
  },
  data() {
    return {
      issue: null,
      isNewReplyModalActive: false,
    }
  },
  mounted() {
    this.getIssue()
  },
  methods: {
    openNewReply() {
      this.isNewReplyModalActive = true
    },
    closeNewReplyModal(reload) {
      if (reload) {
        this.getIssue()
      }
      this.isNewReplyModalActive = false
    },
    async getIssue() {
      this.loading = true
      this.issue = await SupportService.getIssue(this.$route.params.key)
      this.loading = false
    },
    downloadIssueFile(file) {
      SupportService.downloadIssueFile(file.key, file.filename)
    },
    downloadMessageFile(file) {
      SupportService.downloadMessageFile(file.key, file.filename)
    },
  },
}
</script>
