<template>
  <div class="record is-entry">
    <div class="is-flex">
      <div class="record-icon">
        <faicon :icon="getIcon()" />
      </div>
      <div class="record-content">
        <div class="record-title">
          <div class="filename">
            <v-clamp autoresize :max-lines="1" @click="$emit('download', file)">{{
              file.title || file.filename
            }}</v-clamp>
          </div>
        </div>
        <div>
          <div class="has-text-muted record-meta is-flex is-flex-start">
            <a class="has-margin-right" @click="$emit('download', file)">Download</a>
            <span class="has-text-weight-medium has-margin-right">{{ file.size | formatNumber }}</span>
            <span v-if="file.title" class="is-hidden-mobile has-margin-right is-flex-grow">
              <v-clamp autoresize :max-lines="1">{{ file.filename }}</v-clamp>
            </span>
            {{ file.created_date | formatDateTime }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    file: Object,
  },

  methods: {
    getIcon() {
      const extension = this.$options.filters.getExtension(this.file.filename)
      const map = {
        pdf: 'file-pdf',
        png: 'image',
        jpeg: 'image',
        jpg: 'image',
      }
      if (!(extension in map)) {
        return 'file-alt'
      }
      return map[extension]
    },
  },
}
</script>
